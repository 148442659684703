if ($("#backbone-wrapper").length) {
	var currentTab = 0;
	showTab(currentTab);
	progressBarLines();
	$(window).resize(progressBarLines);
}

$(".backbone-picker label").click(function () {
	var mememe = $(this);

	mememe.parents(".backbone-tab").find("button").remove();
	mememe.addClass("loading");

	setTimeout(function () {
		mememe.removeClass("loading");
		nextPrev(1);
		mememe
			.parent()
			.append(
				'<button type="button" onclick="nextPrev(1)">Weiter</button>'
			);
	}, 750);
});

function showBackbone() {
	$("#backbone-intro").hide();
	$("#backbone-wrapper").show();
	progressBarLines();
}

function resetTable() {
	$(".receipt-table tbody").empty();
	$('.backbone-custom-form input[type="hidden"]:not(#submitdate)').remove();
}

function resetForm() {
	showTab(0);
	currentTab = 0;
	$("#nextBtn").show();
	$("#prevBtn, #startBtn, #submitBtn").hide();
	resetTable();
	progressBarLines();
}

function showTab(n) {
	// This function will display the specified tab of the form ...
	var tabs = document.getElementsByClassName("backbone-tab");
	var tabName = tabs[n].getAttribute("data-tab-name");
	$(".backbone-tab:eq(" + n + ")").show();
	$(".backbone-tab:not(:eq(" + n + "))").hide();

	// ... and fix the Previous/Next buttons:
	if (n == 0) {
		$("#prevBtn").hide();
	} else {
		$("#prevBtn").show();
	}
	if (n == tabs.length - 1) {
		$("#nextBtn").hide();
	} else {
		$("#nextBtn").show();
	}

	if (tabName == "receipt") {
		addToTable();
	} else {
		$("#submitBtn").hide();
	}

	currentTab = n;

	progressBarLines();
}

function nextPrev(n) {
	// This function will figure out which tab to display
	var x = document.getElementsByClassName("backbone-tab");
	// Exit the function if any field in the current tab is invalid:
	if (n == 1 && !validateForm()) return false;
	// Hide the current tab:
	x[currentTab].style.display = "none";
	// Increase or decrease the current tab by 1:
	currentTab = currentTab + n;
	// if you have reached the end of the form... :
	if (currentTab >= x.length) {
		//...the form gets submitted:
		document.getElementById("backbone-submit").submit();
		return false;
	}

	document.body.scrollTop = document.documentElement.scrollTop = 0;

	// Otherwise, display the correct tab:
	showTab(currentTab);
	progressBarLines();
}

function validateForm() {
	// This function deals with validation of the form fields
	var x,
		y,
		i,
		valid = true;
	x = document.getElementsByClassName("backbone-tab");
	y = x[currentTab].getElementsByTagName("input");

	radioName = y[0].getAttribute("name");
	elementTab = $(".backbone-tab:eq(" + currentTab + ")");

	if ($("input[name=" + radioName + "]:checked").length) {
		elementTab.find(".tab-error").remove();
		return valid;
	} else {
		if (!elementTab.find(".tab-error").length) {
			elementTab.append(
				'<p class="tab-error">Bitte wählen Sie eine dieser Optionen</p>'
			);
		}
	}
}

function addToTable() {
	resetTable();

	var radioNames = [];
	var total_without_vat = 0;

	$('.backbone-custom-form input[type="radio"]').each(function () {
		var itsName = $(this).attr("name");
		radioNames.push(itsName);
	});

	var radioNames = jQuery.unique(radioNames);

	for (var i = 0; i < radioNames.length; i++) {
		var checkedRadio = $("input[name='" + radioNames[i] + "']:checked");
		var radioTitle = checkedRadio.parent().find(".title").html();
		var radioPrice = checkedRadio.parent().find(".price").html();

		if (radioPrice) {
			var number = parseInt(radioPrice.replace(/[^0-9.]/g, ""));
			var total_without_vat = total_without_vat + number;
			var markup =
				"<tr><td>" +
				radioTitle +
				"</td><td>CHF</td><td>" +
				number +
				".–</td></tr>";
			$(".receipt-table tbody").append(markup);

			$('.sub-tab[data-subtab-name="success"]').append(
				'<input type="hidden" name="' +
					radioNames[i] +
					'" value="' +
					radioTitle +
					'" class="json-pick"><input type="hidden" name="' +
					radioNames[i] +
					'-price" value="' +
					radioPrice +
					'" class="json-pick">'
			);
		}
	}

	var vat = (7.7 / 100) * total_without_vat;
	var total_with_vat = total_without_vat + vat;

	if (total_with_vat) {
		$('.sub-tab[data-subtab-name="success"]').show();
		$('.sub-tab[data-subtab-name="error"]').hide();
		$("#prevBtn, #submitBtn").show();
		$("#startBtn, #nextBtn").hide();
	} else {
		$('.sub-tab[data-subtab-name="success"]').hide();
		$('.sub-tab[data-subtab-name="error"]').show();
		$("#prevBtn, #nextBtn").hide();
		$("#startBtn").show();
	}

	$(".receipt-table .woVat .amount").html(
		Number(total_without_vat).toFixed(2)
	);
	$('.sub-tab[data-subtab-name="success"]').append(
		'<input type="hidden" name="total-without-vat" value="' +
			Number(total_without_vat).toFixed(2) +
			'" class="json-pick">'
	);

	$(".receipt-table .vat .amount").html(Number(vat).toFixed(2));
	$('.sub-tab[data-subtab-name="success"]').append(
		'<input type="hidden" name="vat" value="' +
			Number(vat).toFixed(2) +
			'" class="json-pick">'
	);

	$(".receipt-table .wVat .amount").html(Number(total_with_vat).toFixed(2));
	$('.sub-tab[data-subtab-name="success"]').append(
		'<input type="hidden" name="total-with-vat" value="' +
			Number(total_with_vat).toFixed(2) +
			'" class="json-pick">'
	);
}

function progressBarLines() {
	$(".backbone-custom-form .progress-bar").each(function () {
		var element_count = $(this).find(".step").length;
		var element_width = $(this).find(".step").width();
		var container_width = $(this).width();
		var gap_width =
			(container_width - element_count * element_width) /
			(element_count - 1);
		$(this)
			.find(".line")
			.css("width", gap_width - 16);
	});
}

$(document).on("click", ".backbone-popup-trigger", function (event) {
	event.preventDefault(event);

	var name = $(this).attr("data-popup");
	var prev = $('.ch-popupModal[data-popup="' + name + '"]')
		.find(".slideshow")
		.attr("data-prev");
	var next = $('.ch-popupModal[data-popup="' + name + '"]')
		.find(".slideshow")
		.attr("data-next");

	if (name == "virtuelle-touren" ?? name == "virtuelle-touren") {
		// var timeout = 0;
	} else {
		$('.ch-popupModal[data-popup="' + name + '"] .slideshow').cycle({
			slides: "> .single-slide",
			timeout: 7500,
			prev: "#" + prev,
			next: "#" + next,
			log: false,
		});
	}

	if (name == "virtuelle-touren") {
		$('.ch-popupModal[data-popup="' + name + '"]')
			.find(".single-slide")
			.each(function () {
				var iframe = $(this).attr("data-iframe");
				if (iframe) {
					if ($(this).is(":empty")) {
						$(this).html(
							'<iframe src="' +
								iframe +
								'" frameborder="0" allowfullscreen allow="xr-spatial-tracking"></iframe>'
						);
					}
				}
			});
	}

	$('.ch-popupModal[data-popup="' + name + '"]').fadeIn();
	setTimeout(function () {
		$('.ch-popupModal[data-popup="' + name + '"]').addClass("active");
	}, 500);
});

$(document).on(
	"click",
	".backbone-next, .backbone-prev, .backbone-popup .closePopup",
	function (event) {
		$(this)
			.parents(".carousel")
			.find(".video-inliner")
			.each(function () {
				$(this)
					.find("iframe")
					.attr("src", $(this).find("iframe").attr("src"));
			});
	}
);

// $.fn.BeerSlider = function ( options ) {
//     options = options || {};
//     return this.each(function() {
//         new BeerSlider(this, options);
//     });
// };

// $('.beer-slider').BeerSlider({start: 50});
